import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import News_Entertainment from "./pages/News/News_Entertainment";
import Footer from "./component/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
import Header from "./component/Header/Header";
import Current_Opening from "./pages/Career/Current_Opening";
import ShowNavbar from "./component/ShowNavbar/ShowNavbar";
import Gallery from "./pages/Gallery/Gallery";
import Event from "./pages/Event/Event";
import NotFound from "./pages/PageNotFound/NotFound";
function App() {
  return (
    <div>
      {/* <Navbar/> */}
      <ShowNavbar>
        <Header />
      </ShowNavbar>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route exact path="/news" element={<News_Entertainment />} />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route exact path="/current_opening" element={<Current_Opening />}></Route>
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
      <ShowNavbar>
        <Footer />
      </ShowNavbar>
    </div>
  );
}

export default App;
