import React, { useEffect } from "react";
import "./About.css";
import aboutBg from "../../component/asset/image/about/about.png";
import aboutLeftOne from "../../component/asset/image/about/yalmar-member.jpg";
import Helmet from "react-helmet";
import ScrollReveal from "scrollreveal";
import MelavaEvents from "../../component/MelavaEvents/MelavaEvents";
const About = () => {
  useEffect(() => {
    ScrollReveal().reveal(".card-about-last , .about-second-sec", {
      // ScrollReveal configuration options go here
      duration: 1000,
      distance: "30px",
      origin: "bottom",
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Community app,Social networking,Inclusive platform,Engaging discussions,Meaningful connections,Like-minded individuals,Share experiences,Social community,Connecting people,Vibrant community"
        />
        <meta
          name="description"
          content="Discover Our Community - Yelmar Pariwar is a vibrant and inclusive community app connecting like-minded individuals. Join us to explore engaging discussions, share experiences, and build meaningful connections."
        />
        <title>About Us | Yelmar Pariwar</title>
      </Helmet>
      <section className="about">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active about-bg">
              <img src={aboutBg} className="d-block w-100" alt="aboutBg" />
            </div>
          </div>
        </div>
        <div className="about-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row mt-5">
              <header>
                <h3 className="text-uppercase common-text">
                  About Yelmar Pariwar
                </h3>
                <hr className="heading_line mb-3" />
              </header>
              <div className="col-lg-6 mb-5">
                <div className="about-left-sec">
                  <h5 className="fw-bold">
                    एकाच ठिकाणी मिळणार जॉब , लग्न , आणि आपल्या समाजाबद्दल च्या
                    महत्वाच्या बातम्या .
                  </h5>
                  <p>
                    ह्या ॲप चा उद्देश म्हणजे , आपल्या समाजाच्या तरुण पिढीला एक
                    आपुलकीचे प्लॅटफॉर्म देण्याचा आहे. त्यांच्या नोकरी बद्दलच्या
                    समस्या म्हणा अथवा लग्नाबाबत च्या तक्रारी. ह्या लक्षात घेता
                    या ॲप ला बनवण्यात आले आहे .
                  </p>
                  <p>
                    येलमार परिवार ॲपमध्ये समुदायाचा सर्वांगीण विकास साधता येतो.
                    तसेच सदस्यांचे संवाद, विचार विमर्श, समस्या संबंधित सहाय्य
                    इत्यादी विविध विषयांवर चर्चा आणि मदत करण्यासाठी वापरण्यात
                    येतो.
                  </p>
                  {/* <p>
                    येलमार परिवार ऍपमध्ये समुदायाचं सर्वांगीण विकास साधता येतं.
                    त्यातले सदस्यांचं संवाद, विचार विमर्श, समस्या संबंधित सहाय्य
                    इत्यादी विविध विषयांवर चर्चा आणि मदत करण्यासाठी वापरण्यात
                    येतं.
                  </p>
                  <p>
                    येलमार परिवार ऍपमध्ये विविध गतिविध्यांची सूचना मिळते, जसे की
                    सोशल इव्हेंट्स, कार्यक्रमे, क्लब्स, आणि सोसायटीजच्या आयोजित
                    केलेल्या गोष्टी इत्यादी.
                  </p>
                  <p>
                    विशेषतः, येलमार परिवार ऍपमध्ये आपल्या आवडत्या विषयांवर
                    आधारित ग्रुप्स बनवता येतात, ज्यामध्ये आपण आपल्या समाजिक
                    विचारांच्या वादांवादांमध्ये सहभागी होऊ शकता.
                  </p>
                  <p>
                    एका अभिप्रेत आणि समृद्ध सोशल समुदायाचं तळवार ठेवता येलमार
                    परिवार ऍप आपल्या सामाजिक जीवनातील नवीन आणि सुंदर संवादांची
                    सृष्टी करण्यात आपली सहाय्य करेल. या ऍपच्या माध्यमातून आपले
                    समुदाय आपल्यासाठी अर्थव्यवस्थित, सामाजिक आणि मनोरंजक
                    संवादांचं संचय सुरू करू शकतं.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className="profile-right-about-sec">
                  <div className="about-img-left">
                    <img
                      src={aboutLeftOne}
                      alt="Yelmar-Pariwar"
                      className="rounded img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <header>
                <h3 className="text-uppercase common-text">About</h3>
                <hr className="heading_line mb-4" />
              </header>
              <div className="col-lg-6 about-us-vision-mision">
                <div className="about-our-vision">
                  <div className="card about-card">
                    <div className="card-body about-card-body">
                      <div className="icon-group">
                        <div className="about-icon-common"></div>
                        <img
                          src={require("../../component/asset/image/about/vision.png")}
                          alt="vision"
                          className="img-fluid rounded on-icon-img"
                          width={64}
                        />
                      </div>
                      <h3 className="fw-bold  common-text">Vision</h3>
                      <p className="fw-bold">
                        आपल्या सोशल संवादांची गुढी वातावरण सादर करण्यासाठी,
                        आपल्याला विविधता व संवेगांचं एक संगमस्थान प्रदान
                        करण्याचं आमचं ध्येय आहे. येलमार परिवारच्या माध्यमातून
                        आपल्या समाजाच्या लोकांच्या संवेगांची पर्वाह करून,
                        त्यांच्यासोबत समृद्ध वातावरणात एकत्र येऊन, संवाद
                        साधण्याचा आनंद घेऊन जगाचं एक अधिवेशन सृजन करण्याचा आमचा
                        संकल्प आहे.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-our-vision">
                  <div className="card about-card">
                    <div className="card-body about-card-body">
                      <div className="icon-group">
                        <div className="about-icon-common"></div>
                        <img
                          src={require("../../component/asset/image/about/mision-about.png")}
                          alt="vision"
                          className="img-fluid rounded on-icon-img"
                          width={64}
                        />
                      </div>
                      <h3 className="fw-bold common-text">Mission</h3>
                      <p className="fw-bold">
                        येलमार परिवार ॲपच्या मिशनचा उद्दिष्ट हा आहे कि समाजातील
                        विविध सदस्यांना एकत्र करून, सोशल जगातील तात्काळीक आणि
                        समाजसेवा संबंधित समस्यांवर आपल्या संवेदनशीलता आणि
                        संविधानाने प्रतिक्रिया देणे. या ॲपमध्ये एक सुरंगी,
                        सुमधुर, आणि आत्मनिर्भर समाजाचा विकास करण्याचा प्रमुख
                        उद्देश आहे
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* melavaevent  */}
          <MelavaEvents />
          {/* melavaevent  */}
        </div>
      </section>
    </>
  );
};

export default About;
