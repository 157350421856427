import React, { useEffect } from "react";
import Helmet from "react-helmet";
import "./Gallery.css";
import ScrollReveal from "scrollreveal";

const Gallery = () => {
  useEffect(() => {
    ScrollReveal().reveal(".img-list", {
      // ScrollReveal configuration options go here
      duration: 1000,
      distance: "30px",
      origin: "bottom",
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <div className="gallery-second-sec mt-5 mb-5">
        <div className="container">
          <div className="row mt-5">
            <header>
              <h3 className="common-text">Gallery</h3>
              <hr className="heading_line mb-3" />
            </header>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-1.jpeg")}
                    alt="slide-1"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-2.jpeg")}
                    alt="slide-2"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-3.jpeg")}
                    alt="slide-3"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-4.jpeg")}
                    alt="slide-4"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-5.jpeg")}
                    alt="slide-5"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-6.jpeg")}
                    alt="slide-6"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-7.jpeg")}
                    alt="slide-7"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-8.jpeg")}
                    alt="slide-8"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-list">
                <div className="list">
                  <img
                    src={require("../../component/asset/image/gallery/slide-9.jpeg")}
                    alt="slide-9"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
