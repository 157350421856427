/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import "./Home.css";
import bgOne from "../../component/asset/image/yalmar44.png";
import bgThree from "../../component/asset/image/yalmar2.png";
import bgTwo from "../../component/asset/image/yalmar1.png";
import Helmet from "react-helmet";
import { Carousel } from "react-bootstrap";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HubIcon from "@mui/icons-material/Hub";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Link } from "react-router-dom";
import GalleryCom from "../../component/GalleryComp/GalleryCom";
import MelavaEvents from "../../component/MelavaEvents/MelavaEvents";
import axios from "axios";
import { useState } from "react";
const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetData();
  }, []);

  const fetData = async () => {
    const response = await axios.get(
      "https://yelmarpariwar.com/yelmarpariwar/api/web/achievers",
      {
        auth: {
          username: "yalmarpariwarapp",
          password: "t5AK6/mFx[`7chzJ",
        },
      }
    );
    console.log(response.data.data)
    setData(response.data.data)
  };


  useEffect(() => {
    ScrollReveal().reveal(
      ".box, .achieve ,.img_para , .card_services , .icon-box , .community-sec",
      {
        // ScrollReveal configuration options go here
        duration: 1000,
        distance: "30px",
        origin: "bottom",
        easing: "ease-out",
      }
    );
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="community app, social network, discussion platform, online community, collaboration, connect, engage, like-minded individuals, share knowledge, meaningful connections."
        />
        <meta
          name="description"
          content=" Connect, engage, and collaborate with like-minded individuals in our vibrant community app. Join discussions, share knowledge, and make meaningful connections."
        />
        <title>Home | Yelmar Pariwar</title>
      </Helmet>
      <section className="slider-img" style={{ overflowX: "hidden" }}>
        {/* carousel   */}
        <div className="slider-container ps-0 pe-0 container-fluid">
          <Carousel interval={2000} pauseOnHover>
            <Carousel.Item>
              <img className="d-block w-100" src={bgOne} alt="Image 1" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={bgTwo} alt="Image 2" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={bgThree} alt="Image 3" />
            </Carousel.Item>
            {/* Add more Carousel.Item as needed */}
          </Carousel>
        </div>
        {/* carousel   */}
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="box box1" style={{ borderRadius: "0px" }}>
                <h3>YelmarPariwar.com</h3>
                <p>
                  Welcome to our community website! Our mission is to create an
                  inclusive and vibrant online space where individuals from
                  diverse backgrounds can come together to connect, collaborate,
                  and contribute towards a common purpose.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <div className="box box2">
                    <FavoriteBorderIcon style={{ color: "rgb(165 44 2)" }} />
                    <h5 className="mt-4 fw-bold">Matrimonial</h5>
                    <p>
                      Our platform is dedicated to helping you find your perfect
                      match and embark on a journey of happiness and
                      togetherness.
                    </p>
                    <Link to="/" className="text-decoration-none link_color">
                      Find Life Partner
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="box box3">
                    <HubIcon style={{ color: "rgb(165 44 2)" }} />
                    <h5 className="mt-4 fw-bold">Business Network</h5>
                    <p>
                      Our mission is to facilitate meaningful relationships,
                      foster collaboration, and promote growth opportunities.
                    </p>
                    <Link to="/" className="text-decoration-none link_color">
                      Find Business Partner
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="box box4">
                    <PersonSearchIcon style={{ color: "rgb(165 44 2)" }} />
                    <h5 className="mt-4 fw-bold">Find/Post Jobs</h5>
                    <p>
                      Our mission is to facilitate job opportunities and create
                      a platform where job seekers and employers can connect.
                    </p>
                    <Link to="/" className="text-decoration-none link_color">
                      Find Or Post Job
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* start section achievers */}
        <div className="container mt-5">
          <div className="row">
            <header className="mb-4 mt-3">
              <h3 className="text-center fw-bold">Achievers</h3>
              <p className="text-center">
                Know the people who made community feel proud.
              </p>
            </header>
            {data.map((item)=>{
              return(
                <div key={item.id} className="col-lg-6 mb-3">
                <div className="achieve">
                     <div>
                    <img
                      src={item.image}
                      className="achieve_img"
                      alt="same"
                    />
                  </div>
                  <div className="ms-5">
                    <h4 className="mb-3">{item.name}</h4>
                    <p className="mb-4">
                      {item.description}
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.microdynamicsoftware.yelmarpariwar" target="_blank" className="text-decoration-none browse_profile">
                      BROWSE OF PROFILE
                    </a>
                  </div>
                </div>
              </div>
              )
            })}
            
            {/* <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">Pravin Sargar</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="achieve">
                <div>
                  <img
                    src={require("../../component/asset/image/about/adv-svk.jpeg")}
                    className="achieve_img"
                    alt="same"
                  />
                </div>
                <div className="ms-5">
                  <h4 className="mb-3">first name</h4>
                  <p className="mb-4">
                    Graduate Shastri from Sampurnanand Sanskrit University
                    Varanasi in 2003.Originally belongs to Jaunpur UP. ....
                  </p>
                  <Link to="" className="text-decoration-none browse_profile">
                    BROWSE OF PROFILE
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* gallery */}

        <GalleryCom />

        {/* gallery */}
        {/* end section achievers */}

        {/* start section uniting community */}
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <div className="para">
                <h4 className="color_heading">
                  Uniting Yelmar community across world
                </h4>
                <p>
                  Welcome to our mission of uniting the Yelmar community across
                  the world! Our goal is to create a global network of Yelmar
                  individuals and enthusiasts, transcending geographical
                  boundaries and fostering a strong sense of belonging and
                  solidarity among our members. We believe that by coming
                  together.
                </p>
              </div>
              <div className="img_para">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19 3c-1.654 0-3 1.346-3 3c0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5S4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692l.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3s3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025l1.954-1.954c.421.15.867.247 1.339.247c2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247c1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2a1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5S7.327 7 6.5 7S5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2s2 .897 2 2s-.897 2-2 2zm6-7a1 1 0 1 1 0-2a1 1 0 0 1 0 2z"
                  />
                </svg>
                <div className="ms-3">
                  <h5 className="fw-bold">
                    We help you in business networking
                  </h5>
                  <p>
                    Our mission is to help businesses to sales and purchase
                    within community.
                  </p>
                </div>
              </div>
              <div className="img_para">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 4.595a5.904 5.904 0 0 0-3.996-1.558a5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412l7.332 7.332c.17.299.498.492.875.492a.99.99 0 0 0 .792-.409l7.415-7.415c2.354-2.354 2.354-6.049-.002-8.416a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595zm6.791 1.61c1.563 1.571 1.564 4.025.002 5.588L12 18.586l-6.793-6.793c-1.562-1.563-1.561-4.017-.002-5.584c.76-.756 1.754-1.172 2.799-1.172s2.035.416 2.789 1.17l.5.5a.999.999 0 0 0 1.414 0l.5-.5c1.512-1.509 4.074-1.505 5.584-.002z"
                  />
                </svg>
                <div className="ms-3">
                  <h5 className="fw-bold">Help you find right match for you</h5>
                  <p>
                    One can upload his/her matrimonial profile,and can also make
                    request to get profile details.
                  </p>
                </div>
              </div>
              <div className="img_para">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M11 15h2V9h3l-4-5l-4 5h3z" />
                  <path
                    fill="currentColor"
                    d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
                  />
                </svg>
                <div className="ms-3">
                  <h5 className="fw-bold">
                    Job engine provide you platform to find Job within community
                  </h5>
                  <p>
                    One can post jobs and also apply for current jobs.Also
                    submit your resume.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 community-sec">
              <img
                src={require("../../component/asset/image/gallery/slide-4.jpeg")}
                alt="uniting_img"
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
        {/* end section uniting community */}

        {/* services section card */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <header>
              <h3 className="fw-bold text-center mb-5">Services</h3>
            </header>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/network.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="mt-5 fw-bold">Community Directory</h4>
                  <p className="mt-3">
                    Welcome to our Community Directory! This directory is a
                    comprehensive listing of all the diverse groups,
                    organizations, and individuals that make up our vibrant
                    community.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/job.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="fw-bold mt-5">Job & Career</h4>
                  <p className="mt-3">
                    Yelmar Community, we are dedicated to supporting our members
                    in their job and career aspirations.Our platform offers a
                    range of resources, services, and opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services pb-5">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/matromonial.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="mt-5 fw-bold">Matrimonial</h4>
                  <p className="mt-4">
                    Our platform is dedicated to helping individuals from the
                    Yelmar community find their life partners, fostering
                    meaningful connections, and celebrating the rich cultural
                    heritage of our people.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/business.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="fw-bold mt-5">Business Directory</h4>
                  <p className="mt-3">
                    Our directory serves as a comprehensive and reliable
                    resource for discovering local businesses, services, and
                    professionals within the Yelmar community.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/achievers.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="fw-bold mt-5">Achievers</h4>
                  <p className="mt-3">
                    The program focuses on acknowledging the efforts and
                    accomplishments of community members who have made
                    significant contributions in various fields.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 common-services-box mb-3">
              <div className="card_services">
                <div class="icon-box">
                  <div class="icon">
                    <img
                      src={require("../../component/asset/image/icons/mentors.png")}
                      width={64}
                      className="svg_icon img-fluid"
                      alt="icon"
                    />
                  </div>
                  <h4 className="mt-5 fw-bold">Mentors</h4>
                  <p className="mt-3">
                    The mentorship program is designed to facilitate knowledge
                    sharing, skill development, and personal growth within the
                    community.Mentors are experienced individuals
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end services  section card */}

        {/* melava Events  */}
        <MelavaEvents />
        {/* melava Events  */}
      </section>
    </>
  );
};

export default Home;
