/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PrintIcon from "@mui/icons-material/Print";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import ScrollToTopButton from "../TopScrollBtn/ScrollToTopButton";
const Footer = () => {
  return (
    <>
      <ScrollToTopButton />
      {/* <div className="common-btn-betch">
        <Link to="/contact">
          <img
            src={require("../../component/asset/image/en.png")}
            alt="enquiry-now"
            className="img-fluid"
          />
        </Link>
      </div> */}
      <div className="sidenav">
        <a
          href="https://api.whatsapp.com/send?phone=8055052151"
          class="btn-whatsapp-pulse"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <WhatsAppIcon className="fs-2" />
        </a>
        {/* <a
          href="mailto:microdynamicsoftware@gmail.com"
          className="mail-chat"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <MailIcon style={{ width: "1.3rem" }} />
        </a>
        <a
          href="Tel:8055052151"
          className="phone-chat"
          style={{ cursor: "pointer" }}
        >
          <PhoneIcon style={{ width: "1.3rem" }} />
        </a> */}
      </div>
      <footer className="text-center text-lg-start footer-main text-muted">
        <section className="d-flex social-footer-sec justify-content-center align-items-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span className="fw-bold text-uppercase text-dark">
              Get connected with us on social networks:
            </span>
          </div>
          <div>
            <Link to="https://www.facebook.com/yelmar.pune/" target="_blank" className="me-4 text-reset">
              {/* <FacebookIcon  style={{color:"#3b5998"}}/> */}
              <img
                src={require("../../component/asset/image/footer/facebook.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            <Link to="mailto:yelmarpariwar.mh@gmail.com" target="_blank" className="me-4 text-reset">
              <img
                src={require("../../component/asset/image/footer/gmail.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            <Link to="https://yelmarpariwar.com/" target="_blank" className="me-5 text-reset">
              <img
                src={require("../../component/asset/image/footer/google.png")}
                alt="facebook"
                width={30}
              />
            </Link>
            {/* <Link to="" className="me-4 text-reset">
              <img
                src={require("../../component/asset/image/footer/insta.png")}
                alt="instagram"
                width={30}
              />
            </Link> */}
            {/* <Link to="" className="me-4 text-reset">
              <img
                src={require("../../component/asset/image/footer/linkdin.png")}
                alt="linkedin"
                width={28}
              />
            </Link> */}
            {/* <Link to="" className="me-5 text-reset">
              <img
                src={require("../../component/asset/image/footer/twitter.png")}
                alt="twitter"
                width={30}
              />
            </Link> */}
          </div>
        </section>
        <section className>
          <div className="container text-center text-md-start mt-5">
            <div className="row text-white mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold common-text mb-4">
                  Yelmar Pariwar
                </h6>
                <p>
                  ह्या अँप चा उद्देश म्हणजे , आपल्या समाजाच्या तरुण पिढीला एक
                  आपुलकीचे प्लॅटफॉर्म देण्याचा आहे , त्यांच्या नोकरी बद्दलच्या
                  समस्या म्हणा , अथवा लग्नाबाबत च्या तक्रारी . ह्याला लक्षात
                  घेता . या अँप ला बनवण्यात आले आहे.
                </p>
              </div>
              {/* <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase common-text fw-bold mb-4">
                  Services
                </h6>
                <p>
                  <Link
                    to="/recruitment"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Recruitement
                  </Link>
                </p>
                <p>
                  <Link
                    to="/training"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Training
                  </Link>
                </p>
                <p>
                  <Link
                    to="/job_fair"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Job Fair
                  </Link>
                </p>
                <p>
                  <Link
                    to="/staff_services"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Staff Services
                  </Link>
                </p>
              </div> */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold common-text mb-4">
                  Useful links
                </h6>
                <p>
                  <Link
                    className="text-reset text-decoration-none footer-links"
                    to="/"
                  >
                    Home
                  </Link>
                </p>
                <p>
                  <Link
                    to="/about"
                    className="text-reset text-decoration-none footer-links"
                  >
                    About Us
                  </Link>
                </p>
                <p>
                  <Link
                    to="/news"
                    className="text-reset text-decoration-none footer-links"
                  >
                    News and Entertainment
                  </Link>
                </p>
                <p>
                  <Link
                    to="/event"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Event
                  </Link>
                </p>
                <p>
                  <Link
                    to="/current_opening"
                    className="text-reset text-decoration-none footer-links"
                  >
                    jobs & Careers
                  </Link>
                </p>
                <p>
                  <Link
                    to="/gallery"
                    className="text-reset text-decoration-none footer-links"
                  >
                    Gallery
                  </Link>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold common-text mb-4">
                  Contact
                </h6>
                <p className="footer-links footer-address">
                  <HomeIcon />
                  <p className="ms-2">103, Vitthal Complex, Above Hotel Royal Pure Veg, near Selfy Point, Narhe, Pune, Maharashtra 411041</p>
                </p>
                <Link to="mailto:microdynamicsoftware@gmail.com" className="footer-links footer-address">
                  <EmailIcon />
                  <p className="ms-2" style={{ fontSize: "14px" }}>
                    microdynamicsoftware@gmail.com
                  </p>
                </Link>
                <Link to="tel:8055052151" className="footer-links footer-address">
                  <LocalPhoneIcon />
                  <p className="ms-2">+91-8055052151</p>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div
          className="text-center footer-micro text-white p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2023 Yelmar Pariwar Designed By
          <Link
            className="text-reset  ms-2 fw-bold"
            target="_blank"
            to="https://www.microdynamicsoftware.com/"
          >
            Microdynamic Software Private Limited
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
