import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import aboutBg from "../../component/asset/image/new&envorment .png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
const News_Entertainment = () => {
  useEffect(() => {
    ScrollReveal().reveal(".news-card", {
      // ScrollReveal configuration options go here
      duration: 1000,
      distance: "30px",
      origin: "bottom",
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="News,Entertainment,Community,Trending,Breaking Headlines,Articles,Updates,Vibrant,Curated Content,Community Members,Insights,Trendy,Pop Culture,Events,Celebrity News,Movies,TV Shows,Music,Lifestyle,Social Discussions"
        />
        <meta
          name="description"
          content="Discover the latest news, trends, and entertainment updates on our vibrant community app. Stay informed with breaking headlines, insightful articles, and engaging content, all curated by our passionate community members."
        />
        <title>News & Entertainment | Yelmar Pariwar</title>
      </Helmet>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active about-bg">
            <img src={aboutBg} className="d-block w-100" alt="aboutBg" />
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <header className="mb-3">
            <header>
              <h3 className="text-uppercase common-text">
                News & Entertainment
              </h3>
              <hr className="heading_line mb-3" />
            </header>
          </header>
          <div className="col-lg-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-1.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  येलमार परिवार स्नेह मेळावा
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  २५ ऑगस्ट २०१९ रोजी येलमार परिवार उद्घाटन समारंभ पार पडला, या वेळी येलमार मेळाव्यासाठी नियंत्रित मान्यवर उपस्थित होते तसेच परिवार मधील सर्व लोकांनी भरभरून प्रतिसाद दिला. याच वेळी वेगवेगळ्या स्पर्धा आयोजित केल्या होत्या. मेहंदी स्पर्धा, रांगोळी स्पर्धा, नखावरील नक्षी स्पर्धा, विशेष गौरव सोहळा पार पडला.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                className="image_hover"
                sx={{ height: 140 }}
                image={require("../../component/asset/image/gallery/slide-2.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  मिसेस येलमार २०१९
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  मिसेस येलमार २०१९ च्या या स्पर्धे मध्ये या तीन मिसेस येलमार यांना पारितोषक देवून घोषित करण्यात आले.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-3.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  मिस येलमार २०१९
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  मिस येलमार २०१९ च्या या स्पर्धे मध्ये या तीन मिस येलमार यांना पारितोषक देवून घोषित करण्यात आले.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-4.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  वधू - वर परीचय मेळावा
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  वधू - वर परीचय मेळावा आयोजित केला होता या वेळीसंस्थेच्या तीन हजार विवाहेच्छुक मुला - मुलीचे फोटो व तपशील असलेली सूची पुस्तिका तयार करण्यात आली. या मेळाव्यासाठी नियंत्रित मान्यवर आदी उपस्थित होते. येलमार समजबांधवांनी या उपक्रमाचा लाभ घ्यावा, असे आवाहन आयोजकाच्या वतीने करण्यात आले होते.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 mt-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-5.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  विशेष गौरव सोहळा
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  विशेष गौरव सोहळा या मध्ये मयूर लाडे यांना पारितोषक देवून गौरवीत करण्यात आले .
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 mt-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                className="image_hover"
                sx={{ height: 140 }}
                image={require("../../component/asset/image/gallery/slide-6.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  फॅन्सी ड्रेस कॉम्पिटिशन
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ३ ते ६ या वयोगटातील लहान मुलांनी फॅन्सी ड्रेस मध्ये सर्व मान्य वराना तसेच सर्व येलमार परिवरातील लोकांना आकर्षित केले आणि सर्वांचे मनोरंजन केले.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 mt-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-7.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  गुणवंत मुलांचा गौरव
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  तसेच या मेळाव्या मध्ये गुणवंत मुलांना परितोषक देवून त्यांचा गौरव करण्यात आला.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-3 mt-3 news-card">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                className="image_hover"
                image={require("../../component/asset/image/gallery/slide-8.jpeg")}
                title="green iguana"
              />
              <CardContent>
                <Typography ypography gutterBottom variant="h5" className="fw-bold fs-5 common-text" component="div">
                  रांगोळी स्पर्धा
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  रांगोळी स्पर्धा आयोजित केली होती या वेळी सर्व महिलांनी आपल्या रांगोळीने सर्वांना आकर्षित केले.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default News_Entertainment;
