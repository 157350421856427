import React from "react";

const MelavaEvents = () => {
  return (
    <div className="container">
      <div className="row mb-5 ">
        <div className="col-lg-4 mb-4 last-sec-card-main">
          <div className="card card-about-last">
            <img
              src={require("../../component/asset/image/about/card-1.jpg")}
              className="card-img-top card-about-last-img-sec"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title common-text fw-bold">स्नेह मेळावा</h5>
              <p className="card-text fw-bold">
                येलमार समाजा तर्फे आयोजित करण्यात आलेला कार्यक्रम "येलमार स्नेह
                मेळावा" मध्ये हास्य सम्राट "अजितकुमार कोष्ठी" ह्यांना आमंत्रित
                केल्यामुळे ह्या मेळाव्या ची शोभा खूप वाढली होती. सगळ्या मेळाव्या
                मध्ये हास्य चा वादळ पसरला होता. सगळ्या लोकांनी ह्या मेळाव्या चा
                आनंद लुटला. लोकांना हसवणं म्हणजे खूप कठीण आहे याची प्रचिती आली
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4 last-sec-card-main">
          <div className="card card-about-last">
            <img
              src={require("../../component/asset/image/about/card-2.jpg")}
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title common-text fw-bold">पारितोषिक वाटप</h5>
              <p className="card-text fw-bold">
                येलमार समाजातर्फे आयोजित करण्यात आलेला कार्यक्रम "येलमार
                पारितोषिक वाटप मेळावा " मध्ये मुलं आणि मुली जे परीक्षेत चांगल्या
                गुणांनी उत्तीर्ण झाले आहे, त्यांना पुरस्कारित करण्यात आले.
                ह्याचा एकमात्र उद्देश म्हणजे मुलांना शिक्षणाकडे वळण्याकडे
                प्रेरणा मिळते आणि असं पुरस्कारितकरून त्यांना प्रोत्साहित केले
                जाते.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-4 last-sec-card-main">
          <div className="card card-about-last">
            <img
              src={require("../../component/asset/image/about/card-3.jpg")}
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title common-text fw-bold">लग्न सोहळा</h5>
              <p className="card-text fw-bold">
                येलमार समाजा तर्फे आयोजित करण्यात आलेला कार्यक्रम "येलमार लग्न
                मेळावा " मध्ये एकूण २० ते ३० जोडप्यांचे लग्न लावून त्यांना
                मदतीचा हात देऊन त्यांच्या आयुष्याला वळण देण्याचा एक उपक्रम
                राबवण्यात आला. एवढेच नाही तर असे अनेक सोहळे पार पाडून आपण आपल्या
                समाजाला नवीन चालना देऊ.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MelavaEvents;
