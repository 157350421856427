import React, { useState, useEffect } from "react";
import "./ScrollToTopButton.css";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Link } from "react-router-dom";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const breakpoint = 200; // Adjust this value to determine when to show the button
    setIsVisible(scrollY > breakpoint);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        // <button className="scroll-to-top-button" onClick={scrollToTop}>
        //   Go to Top
        // </button>
        <Link className="scroll-to-top-button" onClick={scrollToTop}>
          <ArrowCircleUpIcon
            className="fs-1"
            style={{ color: "rgb(165 44 2)" }}
          />
        </Link>
      )}
    </>
  );
};

export default ScrollToTopButton;
