import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../component/asset/image/logo.png";
import { useState } from "react";

function Header() {
  const [activeLink, setActiveLink] = useState("");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="bg_Color navbar-main">
      <Container fluid>
        {/* <Navbar.Brand href="#home" className='text-white fw-bold'>Staff Management</Navbar.Brand> */}
        <Link to="/" className={`${activeLink === "home" ? "active login-nav home_btn" : ""
          }`} >
          <img src={logo} className="login-link mb-2 mt-2 ms-2"
            onClick={() => handleLinkClick("home")}
            to="/" height={60} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="fs_nav">
            <Nav className="me-5">
              <Nav.Link
                className={`${activeLink === "home" ? "active login-nav home_btn" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("home")}
                  to="/"
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "about" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("about")}
                  to="/about"
                >
                  About Us
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "news_entertainment" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("news_entertainment")}
                  to="/news"
                >
                  News & Entertainment
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "event" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("event")}
                  to="/event"
                >
                  Event
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "career" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("career")}
                  to="/current_opening"
                >
                  Job & Career
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "gallery" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("gallery")}
                  to="/gallery"
                >
                  Gallery
                </Link>
              </Nav.Link>
              <Nav.Link
                className={`${activeLink === "contact" ? "active login-nav" : ""
                  }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("contact")}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </Nav.Link>
            </Nav>
            <div>
              {/* <Link to="/login" type="button" className="btn contact-btn">
                Log In
              </Link> */}
              <Link
                to="https://play.google.com/store/apps/details?id=com.microdynamicsoftware.yelmarpariwar"
                target="_blank"
                type="button"
                className="btn contact-btn ms-3"
              >
                Download Now
              </Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
