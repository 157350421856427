import React, { useEffect } from "react";
import Helmet from "react-helmet";
import "./Gallery.css";
import galleryBg from "../../component/asset/image/gallery/gallery.png"
import GalleryCom from "../../component/GalleryComp/GalleryCom"
import ScrollReveal from "scrollreveal";

const Gallery = () => {
  useEffect(() => {
    ScrollReveal().reveal(".img-list", {
      // ScrollReveal configuration options go here
      duration: 1000,
      distance: "30px",
      origin: "bottom",
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Community Gallery,Member Artworks,User Photos,Creative Moments,Member-Submitted Images,Community Creations,Visual Showcase,Art and Photography,Creative Expression,Share Moments"
        />
        <meta
          name="description"
          content="Explore our vibrant community's diverse gallery of photos, artworks, and moments shared by members. Join in and showcase your creativity!"
        />
        <title>Gallery | Yelmar Pariwar</title>
      </Helmet>
      <section className="about">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active gallery-bg">
              <img src={galleryBg} className="d-block w-100" alt="galleryBg" />
            </div>
          </div>
        </div>
        <GalleryCom />
      </section>
    </>
  );
};

export default Gallery;
