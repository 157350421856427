import React, { useEffect } from "react";
import Helmet from "react-helmet";
import "./Event.css";
import ScrollReveal from "scrollreveal";
import eventBg from "../../component/asset/image/event/event.png"

const Event = () => {
  useEffect(() => {
    ScrollReveal().reveal(".card-about-last , .about-second-sec", {
      // ScrollReveal configuration options go here
      duration: 1000,
      distance: "30px",
      origin: "bottom",
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="community events, event planning, social gatherings, event organizer, community engagement, event scheduling, event coordination, event hosting."
        />
        <meta
          name="description"
          content="Discover exciting events, organize gatherings, and engage with your community using our top-notch Event Management platform. Join now for unforgettable experiences!"
        />
        <title>Events | Yelmar Pariwar</title>
      </Helmet>
      <section className="about">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active event-bg">
              <img src={eventBg} className="d-block w-100" alt="aboutBg" />
            </div>
          </div>
        </div>
        <div className="about-second-sec mt-5 mb-5">
          <div className="container">
            <div className="row mb-5">
              <header>
                <h3 className="common-text">Events</h3>
                <hr className="heading_line mb-3" />
              </header>
              <div className="col-lg-8">
                <div className="event-left-sec">
                  <h4 className="fw-bold">येलमार परिवार स्नेह मेळावा</h4>
                  <p>
                    २५ ऑगस्ट २०१९ रोजी येलमार परिवार उद्घाटन समारंभ पार पडला, या वेळी येलमार मेळाव्यासाठी नियंत्रित मान्यवर उपस्थित होते तसेच परिवार मधील सर्व लोकांनी भरभरून प्रतिसाद दिला. याच वेळी वेगवेगळ्या स्पर्धा आयोजित केल्या होत्या. मेहंदी स्पर्धा, रांगोळी स्पर्धा, नखावरील नक्षी स्पर्धा, फॅन्सी ड्रेस कॉम्पीटिशन. तसेच ३ मिसेस येलमार आणि ३ मिस येलमार यांना पारितोषिक देण्यात आले. गुणवंत मुलांचा गौरव करण्यात आला. विशेष गौरव सोहळा पार पडला. यातून स्त्रियांचा आणि मुलांचा विकास साधला जातो. त्यांना पुढे वाटचाल करण्यासाठी प्रोत्साहन दिले जाते.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="event-right-sec">
                  <img
                    src={require("../../component/asset/image/gallery/slide-1.jpeg")}
                    alt="slide-1"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5 ">
              <div className="col-lg-4 mb-5 last-sec-card-main">
                <div className="card card-about-last img-hover">
                  <img
                    src={require("../../component/asset/image/gallery/slide-2.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      मिसेस येलमार २०१९
                    </h5>
                    <p className="card-text">
                      मिसेस येलमार २०१९ च्या स्पर्धा मध्ये या ३ मिसेस येलमार
                      यांना परितोषक देऊन घोषित करण्यात आले.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5 last-sec-card-main img-hover">
                <div className="card card-about-last">
                  <img
                    src={require("../../component/asset/image/gallery/slide-3.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      मिस येलमार २०१९
                    </h5>
                    <p className="card-text">
                      मिस येलमार २०१९ च्या स्पर्धा मध्ये या ३ मिस येलमार यांना
                      परितोषक देऊन घोषित करण्यात आले.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5 last-sec-card-main img-hover">
                <div className="card card-about-last">
                  <img
                    src={require("../../component/asset/image/gallery/slide-4.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      वधू - वर परिचय मेळावा
                    </h5>
                    <p className="card-text">
                      वधू - वर परिचय मेळावा आयोजित केला होता. या वेळीसंस्थेच्या
                      तीन हजार विवाहहेच्छूक मुला-मुलींचे फोटो व तपशील असलेली
                      सूची पुस्तिका तयार करण्यात आली.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5 last-sec-card-main img-hover">
                <div className="card card-about-last">
                  <img
                    src={require("../../component/asset/image/gallery/slide-5.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      विशेष गौरव सोहळा
                    </h5>
                    <p className="card-text">
                      विशेष गौरव सोहळा या मध्ये मयूर लाडे यांचा पारितोषिक देऊन गौरव करण्यात आले.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5 last-sec-card-main img-hover">
                <div className="card card-about-last">
                  <img
                    src={require("../../component/asset/image/gallery/slide-6.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      फॅन्सी ड्रेस कॉम्पिटिशन
                    </h5>
                    <p className="card-text">
                      ३ ते ६ वयोगटातील लहान मुलांनी फॅन्सी ड्रेस मध्ये सर्व
                      मान्यवरांना तसेच सर्व येलमार परिवारातील लोकांना आकर्षित
                      केले आणि सर्वांना मनोरंजन केले.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 mb-5 last-sec-card-main img-hover">
                <div className="card card-about-last">
                  <img
                    src={require("../../component/asset/image/gallery/slide-7.jpeg")}
                    className="card-img-top card-about-last-img-sec"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title common-text fw-bold">
                      गुणवंत मुलांचा गौरव
                    </h5>
                    <p className="card-text">
                      तसेच या मेळाव्या मध्ये गुणवंत मुलांना परितोषिका देऊन
                      त्यांचा गौरव करण्यात आला.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5">
              <header>
                <h3 className="common-text">Events</h3>
                <hr className="heading_line mb-3" />
              </header>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-1.jpeg")}
                      alt="slide-1"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-2.jpeg")}
                      alt="slide-2"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-3.jpeg")}
                      alt="slide-3"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-4.jpeg")}
                      alt="slide-4"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-5.jpeg")}
                      alt="slide-5"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-6.jpeg")}
                      alt="slide-6"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-7.jpeg")}
                      alt="slide-7"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-8.jpeg")}
                      alt="slide-8"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-list">
                  <div className="list">
                    <img
                      src={require("../../component/asset/image/gallery/slide-9.jpeg")}
                      alt="slide-9"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Event;
