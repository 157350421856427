import React, { useState } from "react";
import "./Contact.css";
import contactBg from "../../component/asset/image/contact.png";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import PrintIcon from "@mui/icons-material/Print";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_AUTH_USERNAME, API_AUTH_PASSWORD, API_BASE_URL } from "../../Constant/Global"

const Contact = () => {
  const navigate = useNavigate();
  // const [contactInfo,setContactInfo]=useState([])
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    subject: "",
    number: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    number: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactData({
      ...contactData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      subject: "",
      number: "",
      message: "",
    };

    // name validation
    const regex = /^[a-zA-Z\s]+$/;
    if (!contactData.name.trim()) {
      newErrors.name = "Name is required *";
      valid = false;
    } else if (!contactData.name.match(regex)) {
      newErrors.name = "Name Must Be Only Alphabets";
      valid = false;
    } else if (contactData.name.length <= 3) {
      newErrors.name = "Name Must Be 3 Letters *";
      valid = false;
    }

    // Email validation
    if (!contactData.email.trim()) {
      newErrors.email = "Email is required *";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(contactData.email)) {
      newErrors.email = "Email is invalid *";
      valid = false;
    }

    // subject validation

    if (!contactData.subject.trim()) {
      newErrors.subject = "subject is required *";
      valid = false;
    }

    // contact number validation (required field)
    if (!contactData.number.trim()) {
      newErrors.number = "Contact Number is required *";
      valid = false;
    } else if (contactData.number.length != 10) {
      newErrors.number = "Number Must 10 Digits*";
      valid = false;
    }

    // message validation (required field)
    if (!contactData.message.trim()) {
      newErrors.message = "Message is required *";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  // handle form data on submit

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, subject, number, message } = contactData;
    if (validateForm()) {
      console.log("Contact form data:", contactData);
    }
    try {
      const finalData = {
        name: name,
        email: email,
        subject: subject,
        mobile: number,
        message: message,
      };
      const response = await axios.post(
        "https://yelmarpariwar.com/yelmarpariwar/api/web/contact_enquiry",
        finalData,
        {
          auth: {
            username: "yalmarpariwarapp",
            password: "t5AK6/mFx[`7chzJ",
          },
        }
      );
      // console.log(response.data.data);
      if (response.data.status) {
        window.alert(response.data.message);
        // toast.success(response.data.message);
        navigate("/");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="keywords"
          content="contact, us, support, feedback, Yelmar Pariwar team, inquiries"
        />
        <meta
          name="description"
          content="Have questions or feedback? Get in touch with us! Contact the Yelmar Pariwar team for support, inquiries, and suggestions. We're here to help."
        />
        <title>Contact Us | Yelmar Pariwar</title>
      </Helmet>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active contact_bg">
            <img src={contactBg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
      <div className="contact-main mb-5">
        <div className="container">
          <div className="row">
            <header>
              <h4 className="text-uppercase common-text fw-bold text-center mt-5 mb-5">
                Contact Us
              </h4>
              {/* <div className="text-bt-style"></div> */}
            </header>
            <div className="col-lg-6 d-flex  justify-content-center align-items-center ">
              <div className="contact-left-sec">
                {/* <div className="contact-img-left">
                  <img src={contactLogo} className="contact-logo-left img-fluid"  width={100} alt="contact-logo" />
                </div> */}
                <div className="contact-info-left">
                  <ul>
                    <li className="contact-info-para mt-3">
                      <div className="me-3 common-contact-icon-group icon-contact">
                        <HomeIcon className="contact-icon" />
                      </div>
                      <span className="ms-2 address-contact">
                        103, Vitthal Complex, Above Hotel Royal Pure Veg, near
                        Selfy Point, Narhe, Pune, Maharashtra 411041
                      </span>
                    </li>
                    <li className="contact-info-para">
                      <div className="icon-contact-second common-contact-icon-group me-3">
                        <EmailIcon className="contact-icon" />
                      </div>
                      <span>
                        <Link to="mailto:info@microdynamicsoftware.com" className="ms-2 text-decoration-none text-dark ps-1">
                          info@microdynamicsoftware.com
                        </Link>
                      </span>
                    </li>
                    <li className="contact-info-para">
                      <div className="icon-contact-third common-contact-icon-group me-3">
                        <LocalPhoneIcon className="contact-icon" />
                      </div>
                      <span>
                        <Link to="tel:8888717711" className="ms-2 text-decoration-none text-dark number-contact-span">
                          +91 8888717711
                        </Link>
                      </span>
                    </li>
                    {/* <li className="contact-info-para">
                      <p className="me-3">
                        <PrintIcon color="primary" />
                      </p>
                      <span className="ms-2">+ 9022552182</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form">
                <div className="card contact-card">
                  <div className="card-body contact-body">
                    {/* contact form */}
                    <form onSubmit={handleSubmit}>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 mb-4">
                            <h4 className="fw-bold common-text">Contact Now</h4>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                // pattern="[A-Za-z]+" title="Please enter a valid first name with only alphabets."
                                value={contactData.name}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Name"
                              />
                              {errors.name && (
                                <div className="error ms-2  text-danger">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                value={contactData.email}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Email"
                              />
                              {errors.email && (
                                <div className="error  ms-2 text-danger">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                name="subject"
                                value={contactData.subject}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Subject"
                              />
                              {errors.subject && (
                                <div className="error ms-2 text-danger">
                                  {errors.subject}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="number"
                                name="number"
                                value={contactData.number}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Contact Number"
                              />
                              {errors.number && (
                                <div className="error ms-2 text-danger">
                                  {errors.number}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-4">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                placeholder="Message"
                                name="message"
                                value={contactData.message}
                                onChange={handleChange}
                                id=""
                                cols="30"
                                rows="6"
                              ></textarea>
                              {errors.message && (
                                <div className="error ms-2 text-danger">
                                  {errors.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <button className="btn contact-btn">
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer
                        position={"top-center"}
                        autoClose={2000}
                        pauseOnHover={false}
                        closeOnClick={true}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
