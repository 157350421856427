/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import careerBg from "../../component/asset/image/job career.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
const Current_Opening = () => {
  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Job opportunities,Career advancement,Job listings,Professional development,Employment opportunities,Job search,Career growth,Resume building,Interview tips,Skill development,Industry insights,Networking events,Job market trends,Career advice,Career guidance"
        />
        <meta
          name="description"
          content="Discover exciting job opportunities and advance your career with our Community App's comprehensive Job & Career page. Explore a wide range of industries, job listings, and professional development resources to achieve your career goals."
        />
        <title>Current Opening | Yelmar Pariwar</title>
      </Helmet>
      <div className="current-opening">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={careerBg} className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 mt-3 common-text">
                  Superintending Engineer, Pradhanmantri/ Mukhyamantri Gramsadak
                  Yojana Kokan region
                </h4>
                <p>Public works Department, Government of Maharashtra </p>
                <p>Job Location -</p>
                <p></p>
                <p className="mt-5 fw-bold">Posted on 5 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=9421235905"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:yelmarpariwar.mh@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:9421235905"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">Marketing</h4>
                <p>Tejas Agro Irrigation System Pvt.Ltd</p>
                <p>Job Location : Pandharpur</p>
                <p>Zonal Manager </p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=9823487310"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:mayurvibhute00744@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:9823487310"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">...</h4>
                <p>SASA ENGGTECH PVT</p>
                <p>Job Location : Pune, Bhosari</p>
                <p>...</p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=7218362564"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:prajvalyelmar4545@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:7218362564"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">
                  महाराष्ट्र कामगार कल्याण मंडळ महाराष्ट्र शासन
                </h4>
                <p>Atpadi - Sangola Rd</p>
                <p>Job Location : Madgule</p>
                <p>LWF लेबर वेलफेर फंड </p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=7588880166"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:bharatvibhute@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:7588880166"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">Production Supervisor</h4>
                <p>SASA ENGGTECH PVT LTD</p>
                <p>Job Location : Pune</p>
                <p>Person Should have good fabrication knowledge </p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=8055052151"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:yelmarpariwar.mh@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:8055052151"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">Marketing</h4>
                <p>Swara kitchen card</p>
                <p>Job Location : Pune</p>
                <p> marketing </p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=9373896652"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:kandre@sasaengineering.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:9373896652"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
            <div className="col-lg-10">
              <div className="Main">
                <h4 className="fs-5 common-text mt-3">
                  System Support engineer
                </h4>
                <p>PGI Computers</p>
                <p>Job Location : Pune</p>
                <p>We required system support engineer allocation narhe </p>
                <p className="mt-5 fw-bold">Posted on 320 days ago</p>
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <a
                href="https://api.whatsapp.com/send?phone=9011710700"
                className="btn"
                target="_blank"
                style={{
                  cursor: "pointer",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                <WhatsAppIcon />
              </a>
              <a
                href="mailto:yelmar.amol118@gmail.com"
                className="ms-2 btn btn-primary"
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ width: "1.3rem" }} />
              </a>
              <a
                href="Tel:9011710700"
                className="ms-2 btn btn-success"
                style={{ cursor: "pointer" }}
              >
                <PhoneIcon style={{ width: "1.3rem" }} />
              </a>
            </div>
            <hr className="mt-3" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Current_Opening;
