import React from 'react'

const NotFound = () => {
    return (
        <div className='container'>
            <div className="row mt-5 py-5 mb-5">
                <div className="col-lg-12">
                    <div className="page-not-found text-center">
                        <h1 className='fw-bold text-secondary'>404 Page Not Found</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
